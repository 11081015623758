var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container"},[_c('div',{staticClass:"c-sticky-navigation u-flex u-flex-justify-center",class:{
      'is-header-hidden': _vm.isHeaderHidden
    },style:({
      top: _vm.stickyBarOffset
    })},[_c('div',{ref:"scroller",staticClass:"c-sticky-navigation__list-scroller",class:{
        'u-width-100 c-sticky-navigation__list-scroller--no-scroll': _vm.sections.length < 5 && _vm.$mq != 'desktop'
      }},[_c('div',{staticClass:"c-sticky-navigation__list-wrap"},[_c('ul',{staticClass:"c-sticky-navigation__list u-bare-list",class:{
            'u-flex-justify-evenly': _vm.sections.length < 5 && _vm.$mq != 'desktop'
          }},_vm._l((_vm.sections),function(item,index){return _c('li',{key:item.anchorTag,staticClass:"c-sticky-navigation__list-item"},[_c('a',{staticClass:"u-flex u-flex-direction-column",class:{
                'is-active': index == _vm.activeIndex
              },attrs:{"href":("#" + (item.anchorTag))},on:{"click":function($event){$event.preventDefault();return _vm.scrollToBlock(item)}}},[_c('span',{staticClass:"c-sticky-navigation__list-item-icon u-ml--a u-mr--a"},[(item.icon)?_c('img',{attrs:{"src":item.icon}}):_vm._e()]),_vm._v(" "+_vm._s(_vm.isNotDesktop ? item.mobileAnchorTitle : item.anchorTitle)+" ")])])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }